import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils/auth'
import store from '../store'
import { Toast } from 'vant'
import i18n from '@/i18n'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/index'),
    children: [
      {
        path: '/',
        name: 'index',
        meta: {
          index: 1
        },
        component: () => import('@/views/index/index'),
      },
      {
        path: '/login',
        name: 'login',
        meta: {
          index: 2
        },
        component: () => import('@/views/login/login'),
      },
      {
        path: '/to-login',
        name: 'to-login',
        meta: {
          index: 2
        },
        component: () => import('@/views/login/tologin'),
      },
      {
        path: '/register',
        name: 'register',
        meta: {
          index: 2
        },
        component: () => import('@/views/login/register'),
      },
      {
        path: '/forget',
        name: 'forget',
        meta: {
          index: 2
        },
        component: () => import('@/views/login/forget'),
      },
      {
        path: '/shop/create',
        name: 'shopCreate',
        meta: {
          index: 2
        },
        component: () => import('@/views/shop/create'),
      },
    
      {
        path: '/flashDeal',
        name: 'flashDeal',
        meta: {
          index: 2
        },
        component: () => import('@/views/flashDeal/flashDeal'),
      },
      {
        path: '/flashDeal/detail',
        name: 'flashDealDetail',
        meta: {
          index: 3
        },
        component: () => import('@/views/flashDeal/detail'),
      },
      {
        path: '/blog',
        name: 'blog',
        meta: {
          index: 2
        },
        component: () => import('@/views/blog/blog'),
      },
      {
        path: '/blog/detail',
        name: 'blogDetail',
        meta: {
          index: 3
        },
        component: () => import('@/views/blog/detail'),
      },
      {
        path: '/category',
        name: 'category',
        meta: {
          index: 2
        },
        component: () => import('@/views/category/category'),
      },
      {
        path: '/kefu',
        name: 'kefu',
        meta: {
          index: 2
        },
        component: () => import('@/views/index/kefu'),
      },
      {
        path: '/category/detail',
        name: 'categoryDetail',
        meta: {
          index: 3
        },
        component: () => import('@/views/category/detail'),
      },
      {
        path: '/company',
        name: 'company',
        meta: {
          index: 2
        },
        component: () => import('@/views/company/company'),
      },
      {
        path: '/seller',
        name: 'seller',
        meta: {
          index: 2
        },
        component: () => import('@/views/seller/seller'),
      },
      {
        path: '/shopInfo',
        name: 'shopInfo',
        meta: {
          index: 3
        },
        component: () => import('@/views/seller/shopInfo'),
      },
      {
        path: '/compare',
        name: 'compare',
        meta: {
          index: 2
        },
        component: () => import('@/views/index/compare'),
      },
      {
        path: '/affiliate',
        name: 'affiliate',
        meta: {
          index: 2
        },
        component: () => import('@/views/shop/affiliate'),
      },
      {
        path: '/trackOrder',
        name: 'trackOrder',
        meta: {
          index: 2
        },
        component: () => import('@/views/order/trackOrder'),
      },
      {
        path: '/sellerPolicy',
        name: 'sellerPolicy',
        meta: {
          index: 3
        },
        component: () => import('@/views/content/sellerPolicy'),
      },
      {
        path: '/returnPolicy',
        name: 'returnPolicy',
        meta: {
          index: 3
        },
        component: () => import('@/views/content/returnPolicy'),
      },
      {
        path: '/supportPolicy',
        name: 'supportPolicy',
        meta: {
          index: 3
        },
        component: () => import('@/views/content/supportPolicy'),
      },
      {
        path: '/privacyPolicy',
        name: 'privacyPolicy',
        meta: {
          index: 3
        },
        component: () => import('@/views/content/privacyPolicy'),
      },
      {
        path: '/terms',
        name: 'terms',
        meta: {
          index: 3
        },
        component: () => import('@/views/content/terms'),
      },
      {
        path: '/shippingRule',
        name: 'shippingRule',
        meta: {
          index: 3
        },
        component: () => import('@/views/content/shippingRule'),
      },
      {
        path: '/product',
        name: 'product',
        meta: {
          index: 3
        },
        component: () => import('@/views/shop/product'),
      },
      {
        path: '/cart',
        name: 'cart',
        meta: {
          index: 3
        },
        component: () => import('@/views/shop/cart'),
      },
     
      {
        path: '/mine',
        name: 'mine',
        meta: {
          index: 2
        },
        component: () => import('@/views/mine/mine'),
        children: [
          {
            path: '/dashboard',
            name: 'dashboard',
            meta: {
              index: 3
            },
            component: () => import('@/views/mine/dashboard'),
          },
          {
            path: '/purchase_history',
            name: 'purchase_history',
            meta: {
              index: 3
            },
            component: () => import('@/views/mine/purchase_history'),
          },
          {
            path: '/download',
            name: 'download',
            meta: {
              index: 3
            },
            component: () => import('@/views/mine/download'),
          },
          {
            path: '/order',
            name: 'order',
            meta: {
              index: 3
            },
            component: () => import('@/views/mine/order'),
          },
          {
            path: '/buyOrderDetail',
            name: 'buyOrderDetail',
            meta: {
              index: 3
            },
            component: () => import('@/views/mine/buyOrderDetail'),
          },
          {
            path: '/orderList',
            name: 'orderList',
            meta: {
              index: 3
            },
            component: () => import('@/views/mine/orderList'),
          },
          {
            path: '/taskDetail',
            name: 'taskDetail',
            meta: {
              index: 3
            },
            component: () => import('@/views/mine/taskDetail'),
          },
          {
            path: '/refund',
            name: 'refund',
            meta: {
              index: 3
            },
            component: () => import('@/views/mine/refund'),
          },
          {
            path: '/wishlist',
            name: 'wishlist',
            meta: {
              index: 3
            },
            component: () => import('@/views/mine/wishlist'),
          },
          {
            path: '/conversations',
            name: 'conversations',
            meta: {
              index: 3
            },
            component: () => import('@/views/mine/conversations'),
          },
          {
            path: '/conversationsDetail',
            name: 'conversationsDetail',
            meta: {
              index: 4
            },
            component: () => import('@/views/mine/conversationsDetail'),
          },
          {
            path: '/wallet',
            name: 'wallet',
            meta: {
              index: 3
            },
            component: () => import('@/views/mine/wallet'),
          },
          {
            path: '/point',
            name: 'point',
            meta: {
              index: 3
            },
            component: () => import('@/views/mine/point'),
          },
          {
            path: '/ticket',
            name: 'ticket',
            meta: {
              index: 3
            },
            component: () => import('@/views/mine/ticket'),
          },
          {
            path: '/ticketDetail',
            name: 'ticketDetail',
            meta: {
              index: 4
            },
            component: () => import('@/views/mine/ticketDetail'),
          },
          {
            path: '/safePassword',
            name: 'safePassword',
            meta: {
              index: 3
            },
            component: () => import('@/views/mine/safePassword'),
          },
          {
            path: '/userInfo',
            name: 'userInfo',
            meta: {
              index: 3
            },
            component: () => import('@/views/mine/userInfo'),
          },
          {
            path: '/notice',
            name: 'notice',
            meta: {
              index: 3
            },
            component: () => import('@/views/mine/notice'),
          },
        ]
      },
      
      
    ]
  },
  {
    path: '/',
    name: 'seller',
    component: () => import('@/views/backend/layout'),
    children: [
      {
        path: '/seller/dashboard',
        name: 'sellerDashboard',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/dashboard'),
      },
      {
        path: '/seller/product/list',
        name: 'productList',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/product/productList'),
      },
      {
        path: '/seller/product/edit',
        name: 'editProduct',
        meta: {
          index: 4
        },
        component: () => import('@/views/backend/product/editProduct'),
      },
      {
        path: '/seller/product/reviews',
        name: 'productReviews',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/product/reviewList'),
      },
      {
        path: '/seller/product/storehouse',
        name: 'productStorehouse',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/product/storehouse'),
      },
      {
        path: '/seller/orders',
        name: 'sellerOrders',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/order/orderList'),
      },
      {
        path: '/seller/orderDetail',
        name: 'orderDetail',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/order/orderDetail'),
      },
      {
        path: '/seller/package',
        name: 'sellerPackage',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/shop/package'),
      },
      {
        path: '/seller/packagePayList',
        name: 'packagePayList',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/shop/packagePayList'),
      },
      {
        path: '/seller/spreadPackage',
        name: 'spreadPackage',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/shop/spreadPackage'),
      },
      {
        path: '/seller/spreadPackagePayList',
        name: 'spreadPackagePayList',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/shop/spreadPackagePayList'),
      },
      {
        path: '/seller/affiliate',
        name: 'sellerAffiliate',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/setting/affiliate'),
      },
      {
        path: '/seller/financialCenter',
        name: 'financialCenter',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/setting/financialCenter'),
      },
      {
        path: '/seller/conversations',
        name: 'sellerConversations',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/setting/conversations'),
      },
      {
        path: '/seller/sellerConversationsDetail',
        name: 'sellerConversationsDetail',
        meta: {
          index: 3
        },
        component: () => import('@/views/backend/setting/sellerConversationsDetail'),
      },
      {
        path: '/seller/conversationDetail',
        name: 'conversationDetail',
        meta: {
          index: 3,
          parent: 'sellerConversations'
        },
        component: () => import('@/views/backend/setting/conversationDetail'),
      },
      {
        path: '/seller/shopSetting',
        name: 'shopSetting',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/shop/setting'),
      },
      {
        path: '/seller/coupon',
        name: 'sellerCoupon',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/shop/coupon'),
      },
      {
        path: '/seller/addCoupon',
        name: 'sellerAddCoupon',
        meta: {
          index: 3,
          parent: 'sellerCoupon'
        },
        component: () => import('@/views/backend/shop/addCoupon'),
      },
      {
        path: '/seller/refund',
        name: 'sellerRefund',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/setting/refund'),
      },
      {
        path: '/seller/payments',
        name: 'sellerPayments',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/setting/payments'),
      },
      {
        path: '/seller/commission',
        name: 'sellerCommission',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/setting/commission'),
      },
      {
        path: '/seller/creditScore',
        name: 'sellerCreditScore',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/setting/creditScore'),
      },
      {
        path: '/seller/productQueries',
        name: 'productQueries',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/product/productQueries'),
      },
      {
        path: '/seller/productQueryDetail',
        name: 'productQueryDetail',
        meta: {
          index: 3,
          parent: 'productQueries'
        },
        component: () => import('@/views/backend/product/productQueryDetail'),
      },
      {
        path: '/seller/ticketDetail',
        name: 'sellerTicketDetail',
        meta: {
          index: 3,
          parent: 'sellerTicket'
        },
        component: () => import('@/views/backend/order/ticketDetail'),
      },
      {
        path: '/seller/ticket',
        name: 'sellerTicket',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/order/ticket'),
      },
      {
        path: '/seller/fileList',
        name: 'sellerFileList',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/file/fileList'),
      },
      {
        path: '/seller/password',
        name: 'sellerPassword',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/setting/password'),
      },
      {
        path: '/seller/userInfo',
        name: 'sellerUserInfo',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/setting/userInfo'),
      },
      {
        path: '/seller/verify',
        name: 'sellerVerify',
        meta: {
          index: 3,
        },
        component: () => import('@/views/backend/setting/verify'),
      },
    ]
  },
  {
    path: '/app',
    name: 'app',
    meta: {
      index: 2
    },
    component: () => import('@/views/index/download'),
  },
  {
    path: '*',
    redirect: '/index'
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path.indexOf('/seller/') > -1) { // 商家后台
    document.body.className = 'sellerBox'
  }
  if (getToken() && ['login', 'register', 'forget', 'shopCreate'].indexOf(to.name) > -1) { // 已登录不能再访问登录页面
    let msg = i18n.t('nin-yi-deng-lu')
    Toast.fail(msg)
    next('/')
  } else {
    next()
  }
})

router.afterEach((to, from, next) => {
  if (to.path.indexOf('/seller/') == -1) { // 商家后台
    document.body.className = ''
  }
  window.scrollTo({top: 0})
})

export default router
