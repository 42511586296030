<template>
  <div id="app" @click="bodyClick">
    <transition :name="transitionName">
      <router-view></router-view>
    </transition>

  </div>
</template>
<script>
import { configList, userInfo } from '@/api/user'
import {getToken, getUserType} from '@/utils/auth'
import {kefuList} from "@/api";

export default {
  data() {
    return {
      transitionName: '',
      hideKefu: false,
      inter: undefined
    };
  },
  watch: {
    isLogin(val) {
      if (val) {
        this.initUser()
      }
    }
  },
  computed: {
    kefuLink() {
      return this.$store.state.lineKf
    },
    isLogin() {
      return this.$store.state.isLogin
    },
  },
  created() {
    this.init()
  },
  mounted() {
    // let locale = localStorage.getItem('locale')
    // this.i18n.locale = locale ? locale : 'en'
    this.$store.state.isLogin = getToken()
    this.$store.state.userType = getUserType()
    console.log("this.$store.state.userType:",this.$store.state.userType)
    this.runMeiqiaScript()

    window.addEventListener('resize', () => {
      let width = window.innerWidth
      this.$store.state.touchable = width < 768
    })
  },
  methods: {
    setFavicon(href) {
      let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = href;
      document.getElementsByTagName('head')[0].appendChild(link);
    },
    initUser() {
      userInfo().then(res => {
        if (res.code == 0) {
          window.localStorage.setItem("Remark",res.data.Remark)
          this.$store.state.userInfo = res.data
        }
      })
    },
    init() {
      configList().then(res => {
        let data = res.data
        if(data['app_name'] !== undefined) {
          this.$store.state.appName = data['app_name'].Value
          document.title =  this.$store.state.appName
        }
        if(data['app_promotion'] !== undefined) {
          this.$store.state.app_promotion = data['app_promotion'].Value
        }
        if(data['withdraw_type'] !== undefined) {
          this.$store.state.withdraw_type = data['withdraw_type'].Value
        }
        if(data['logo'] !== undefined) {
          this.$store.state.logo = data['logo'].Value
        }
        if(data['icon'] !== undefined) {
          this.setFavicon("/upload/tmp/"+data['icon'].Value);
        }
      })
    },
    bodyClick() {
      this.$store.state.bodyClick = !this.$store.state.bodyClick
    },
    insertScript(src,data) {
      let that = this
      // 创建一个新的 <script> 元素
      const script = document.createElement('script');

      // 设置 <script> 的 src 属性为你的文件路径
      script.src = src;

      // 使用 onload 事件处理程序来确保脚本加载完成后执行操作
      script.onload = () => {
        window.chatwootSDK.run({
          websiteToken: data.Token,
          baseUrl: data.Link
        });
        that.inter = setInterval(function(){
          if(document.getElementsByClassName("woot-widget-bubble")[0] !== undefined) {
            clearInterval(that.inter);
            window.$chatwoot.toggleBubbleVisibility('hide');
          }
        },50);
        // 在这里你可以执行脚本加载后的操作
      };
      // 将 <script> 元素添加到页面的 <head> 中，或者你希望的位置
      document.head.appendChild(script);
    },
    runMeiqiaScript() {
      //客服
      kefuList().then(res => {
        let that = this
        // console.log(res.data.Items)
        // this.kefuList = res.data.Items
        for(var i in res.data.Items) {
          let data = res.data.Items[i]
          window.localStorage.setItem("kefuType",data.Type)
          if(data.Type === 'meiqia') {
            const script = document.createElement('script');
            script.async = true;
            script.charset = 'UTF-8';
            script.src = 'https://static.meiqia.com/dist/meiqia.js';
            document.head.appendChild(script);
            window._MEIQIA = window._MEIQIA || function() {
              (window._MEIQIA.a = window._MEIQIA.a || []).push(arguments);
            };
            _MEIQIA = window._MEIQIA
            // console.log(data)
            this.kefuUrl = data.Link + '&language=en&metadata={"ID":"' +window.localStorage.WebUserId+ '","email":"'+  window.localStorage.WebUserName+'"}'
            // this.runMeiqiaScript()
            const match = this.kefuUrl.match(/eid=([^&]+)/);
            const eidValue = match ? match[1] : null;
            _MEIQIA('entId', eidValue);
            _MEIQIA('withoutBtn');
            _MEIQIA('hidePanel');
            _MEIQIA('language','en')
            // 获取聊天窗口可见性
            _MEIQIA('getPanelVisibility', function (visibility){
              console.log("getPanelVisibility")
              // if (visibility === 'visible') {
              //   _MEIQIA('hidePanel');
              // }
            });
            // 初始化成功时的回调
            _MEIQIA('allSet', function (){
              // 你可以根据自己的需求编写相应的代码
              console.log('美洽网站插件初始化完毕。');
            });
            //点击关闭按钮后的回调
            _MEIQIA('beforeCloseWindow', function() {
              // 返回
              // that.$router.push({
              //   name: 'index'
              // })
            });
          }
          if (data.Type === 'livechatinc') {
            this.LiveChat = true
            this.license = data.Link
          }
          if(data.Type === 'chatwoot') {
            window.localStorage.setItem("kefu_info",JSON.stringify(data))
            const regex = /\/(\d+)\//;
            let src = data.Link + "/packs/js/sdk.js"
            this.insertScript(src,data)
          }
        }

      })
    },
  }
}
</script>


<style lang="less">
</style>