import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@vant/touch-emulator';
import FastClick from 'fastclick'
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';
import zhTW from 'vant/es/locale/lang/zh-TW';
import { Locale } from 'vant';
import { Icon } from 'vant';
import * as Sentry from "@sentry/vue";
Vue.use(Icon);
if(localStorage.getItem('locale') == 'en'){
  Locale.use('en-US', enUS);
} else {
  Locale.use('zh-TW', zhTW);
}

import "ant-design-vue/lib/select/style/index.css";
import "ant-design-vue/lib/date-picker/style/index.css";
import { Select, DatePicker } from 'ant-design-vue';
Vue.use(Select).use(DatePicker)


Vue.config.productionTip = false

import './assets/css/common.less';
import './assets/fonts/iconfont.css';
// import 'lib-flexible/flexible'

import i18n from './i18n/index'

import VueClipboard from 'vue-clipboard2'
import {getUser, getUserId} from "@/utils/auth";
Vue.use(VueClipboard)

Vue.prototype.i18n = i18n


const meiqia = function () {
  _MEIQIA('withoutBtn');
  _MEIQIA('showPanel');
  _MEIQIA('language','en')
  // 传递顾客信息
  _MEIQIA('metadata', {
    ID: getUserId(), // 美洽默认字段
    name: getUser(), // 美洽默认字段
    username: getUser(), // 美洽默认字段
  });
  _MEIQIA('getPanelVisibility', function (visibility){
    console.log("customerList getPanelVisibility")
    if (visibility === 'visible') {
      // _MEIQIA('hidePanel');
    }
  });
}

const ChatWoot = function () {
 let kefu = JSON.parse(window.localStorage.getItem('kefu_info'))
  if (kefu === null) {
    return
  }

  console.log("window.localStorage.username")
  let Remark = window.localStorage.getItem("Remark")
  let username = window.localStorage.getItem("username")
    if(username !== null) {
        let obj = {
            name:"账号："+ username + " 备注：" + Remark, // Name of the user
            email: "", // Email of the user
            Remark: Remark, // Email of the user
            // identifier_hash: that.kfData.IdentifierHash // Identifier Hash generated in the previous step
        }
        // console.log(that.kfData.IdentifierHash)
        // if(js.identifierHash !== undefined) {
        //   obj.identifier_hash = js.identifierHash
        // }
        window.$chatwoot.setUser(window.localStorage.username, obj);
    }

  window.$chatwoot.toggle('open');
}

// 定义一个全局函数
Vue.prototype.$kefu = function() {
  let Type = window.localStorage.getItem('kefuType')
  switch (Type) {
    case 'meiqia':
        meiqia()
          break
    case 'chatwoot':
      ChatWoot()
          break
  }
  // 在这里编写全局函数的逻辑
};
FastClick.prototype.focus = function(targetElement) {
  var length;
  var deviceIsWindowsPhone = navigator.userAgent.indexOf("Windows Phone") >= 0;
  var deviceIsIOS = /iP(ad|hone|od)/.test(navigator.userAgent) && !deviceIsWindowsPhone;
  //兼容处理:在iOS7中，有一些元素（如date、datetime、month等）在setSelectionRange会出现TypeError
  //这是因为这些元素并没有selectionStart和selectionEnd的整型数字属性，所以一旦引用就会报错，因此排除这些属性才使用setSelectionRange方法
  if (deviceIsIOS && targetElement.setSelectionRange && targetElement.type.indexOf('date') !== 0 && targetElement.type !== 'time' && targetElement.type !== 'month' && targetElement.type !== 'email') {
    length = targetElement.value.length;
    targetElement.setSelectionRange(length, length); //修复bug ios 11.3不弹出键盘，这里加上聚焦代码，让其强制聚焦弹出键盘
    targetElement.focus();
  } else {
    targetElement.focus();
  }
}


Sentry.init({
    Vue,
    dsn: "https://144dc39a8f9e5c43030bb1819565a4ab@o4504661633400832.ingest.sentry.io/4506243476946944",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
