import Cookies from "js-cookie";

const TokenKey = "WebToken";
const UserKey = "WebUserName";
const UserIdKey = "WebUserId";
const UserTypeKey = "WebUserType";

export function getToken() {
  return window.localStorage.getItem(TokenKey)
}

export function setToken(token) {
  window.localStorage.setItem(TokenKey, token);
}

export function removeToken() {
  window.localStorage.removeItem("username")
  window.localStorage.removeItem(TokenKey);
}

export function getUserType() {
  return window.localStorage.getItem(UserTypeKey);
}

export function setUserType(usertype) {
  window.localStorage.setItem(UserTypeKey, usertype);
}

export function removeUserType() {
  window.localStorage.removeItem(UserTypeKey);
}

export function getUser() {
  return window.localStorage.getItem(UserKey);
}

export function setUser(user) {
  window.localStorage.setItem(UserKey, user);
}

export function removeUser() {
  Cookies.remove(UserKey);
}

export function getUserId() {
  return window.localStorage.getItem(UserIdKey);
}

export function setUserId(id) {
  window.localStorage.setItem(UserIdKey, id);
}

export function removeUserId() {
  window.localStorage.removeItem(UserIdKey);
}
