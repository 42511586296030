import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '../store'
Vue.use(VueI18n)

// 国际化
const i18n = new VueI18n({
  locale: store.state.language || 'en', // set locale
  messages: {
    'zh-CN': require('./lang/zh-CN.yaml'), // 简体中文语言包
    'zh-TW': require('./lang/zh-TW.yaml'), // 繁体中文语言包
    'ar': require('./lang/ar.yaml'), // 阿拉伯语
    'en': require('./lang/en.yaml'), // 英文
    'es': require('./lang/es.yaml'), // 西班牙语
    'fr': require('./lang/fr.yaml'), // 法语
    'id': require('./lang/id.yaml'), // 印尼语
    'ja': require('./lang/ja.yaml'), // 日语
    'ko': require('./lang/ko.yaml'), // 韩语
    'pt': require('./lang/pt.yaml'), // 葡萄牙语
    'ru': require('./lang/ru.yaml'), // 俄语
    'th': require('./lang/th.yaml'), // 泰语
    'he': require('./lang/he.yaml'), // 希伯来语
    'tr': require('./lang/tr.yaml'), // 土耳其语
    'hi': require('./lang/hi.yaml'), // 印地语
    'vi': require('./lang/vi.yaml'), // 越南语
    'de': require('./lang/de.yaml'), // 德语
    'it': require('./lang/it.yaml'), // 意大利语
    'kk': require('./lang/kk.yaml'), // 哈萨克斯坦语
    'ms': require('./lang/ms.yaml'), // 马来语
    'nl': require('./lang/nl.yaml'), // 荷兰语
    'pl': require('./lang/pl.yaml'), // 波兰语
    'ro': require('./lang/ro.yaml'), // 罗马尼亚语
    'sv': require('./lang/sv.yaml'), // 瑞典语
    'uk': require('./lang/uk.yaml'), // 乌克兰语
    'el': require('./lang/el.yaml'), // 希腊语
    'bg': require('./lang/bg.yaml'), // 保加利亚语
    'hr': require('./lang/hr.yaml'), // 克罗地亚语
    'fa': require('./lang/fa.yaml'), // 波斯语
    'ur': require('./lang/ur.yaml'), // 乌尔都语
    'hu': require('./lang/hu.yaml'), // 匈牙利语
    'da': require('./lang/da.yaml'), // 丹麦语
    'fi': require('./lang/fi.yaml'), // 芬兰语
    'cs': require('./lang/cs.yaml'), // 捷克语
    // 缅甸语
    'my': require('./lang/my.yaml'),
  },
  silentTranslationWarn: false,
})


export default i18n
