import fetch from "../utils/fetch";


// 首页商品分类
export function goodsGroup(data) {
  return fetch({
    url: '/api/user/goods-group',
    method: 'post',
    data: data
  });
}
// 分类
export function getCategoryList(data) {
  return fetch({
    url: '/api/user/category',
    method: 'post',
    data: data
  });
}

// getArticleLists
export function getArticleLists(data) {
  return fetch({
    url: '/api/common/article-list',
    method: 'post',
    data: data
  });
}

// 产品列表
export function getProductList(data) {
    return fetch({
        url: '/api/user/product-list',
        method: 'post',
        data: data
    });
}


// 商品列表
export function getGoodsList(data) {
  return fetch({
    url: '/api/user/goods',
    method: 'post',
    data: data
  });
}


export function getMerchantGoodsList(data) {
    return fetch({
        url: '/api/merchant/goods',
        method: 'post',
        data: data
    });
}

// ProductInfo
export function getProductInfo(id) {
  return fetch({
    url: `/api/user/product-info/${id}`,
    method: 'get',
  });
}

// 商品详情
export function goodsDetail(id) {
  return fetch({
    url: `/api/user/goods-info/${id}`,
    method: 'get',
  });
}
// 加入购物车
export function addCart(data) {
  return fetch({
    url: '/api/user/shopping-cart-add',
    method: 'post',
    data: data
  });
}
// 购物车列表
export function getCartList(data) {
  return fetch({
    url: '/api/user/shopping-cart',
    method: 'post',
    data: data
  });
}
// 购物车删除
export function cardRemove(data) {
  return fetch({
    url: '/api/user/shopping-cart-del',
    method: 'post',
    data: data
  });
}

// DialogueList
export function dialogueList(data) {
    return fetch({
        url: '/api/common/dialogue-list',
        method: 'post',
        data: data
    });
}

// DialogueReply
export function dialogueReply(data) {
    return fetch({
        url: '/api/common/dialogue-reply',
        method: 'post',
        data: data
    });
}

// DialogueAdd
export function dialogueAdd(data) {
    return fetch({
        url: '/api/common/dialogue-add',
        method: 'post',
        data: data
    });
}
// 收货地址列表
export function getAddressList(data) {
  return fetch({
    url: '/api/user/DeliveryAddress-list',
    method: 'post',
    data: data
  });
}
// 新增收货地址
export function addAddress(data) {
  return fetch({
    url: '/api/user/DeliveryAddress-add',
    method: 'post',
    data: data
  });
}
// 切换默认收货地址
export function selectAddress(data) {
  return fetch({
    url: '/api/user/DeliveryAddress-select',
    method: 'post',
    data: data
  });
}
// 客服列表
export function kefuList(data) {
  return fetch({
    url: '/api/user/customer-list',
    method: 'post',
    data: data
  });
}
// 聊天历史
export function chatList(data) {
  return fetch({
    url: '/api/common/chat-list',
    method: 'post',
    data: data
  });
}
// 聊天列表
export function chatGroup(data) {
  return fetch({
    url: '/api/common/chat-group',
    method: 'post',
    data: data
  });
}
// 顶部搜索
export function commonSearch(data) {
  return fetch({
    url: '/api/common/search',
    method: 'post',
    data: data
  });
}
// 店铺信息
export function shopPublicInfo(data) {
  return fetch({
    url: '/api/merchant/shop-public-info',
    method: 'post',
    data: data
  });
}
// 通知列表
export function getNoticeList(data) {
  return fetch({
    url: '/api/common/notice-list',
    method: 'post',
    data: data
  });
}
// NoticeRead
export function noticeRead(data) {
    return fetch({
        url: '/api/common/notice-read',
        method: 'post',
        data: data
    });
}

