
import Stomp from 'stompjs';
import store from '../store'

export function initWebSocket( websocketonmessage, reconnect) {
  // let socketUrl = 'ws://172.104.108.97:5001/api/user/ws';
  let socketUrl = 'ws://'+window.location.host+'/api/user/ws';

  let retryCount = 0; // 重试次数
  const maxRetryCount = 10; // 最大重试次数
  let retryInterval = 100; // 重试间隔时间（单位：毫秒）

  function increaseInterval() {
    retryCount++;
    retryInterval = Math.pow(2, retryCount) * 100; // 重试间隔时间指数递增
    retryInterval = Math.min(retryInterval, 60000); // 重试间隔时间最大不超过 1 分钟
  }

  return new Promise((resolve) => {
    function connect() {
      let websocket = new WebSocket(socketUrl);
      websocket.onopen = function(evt) {
        console.log('open');
        store.state.isClose = false
        store.state.isSend = false
        resolve(websocket);
        retryCount = 0; // 连接成功后重试次数清零
        retryInterval = 100; // 连接成功后重试间隔时间重置为 100 毫秒
      };
      websocket.onclose = function(evt) {
        console.log('close');
        store.state.isSend = false;
        if (reconnect && retryCount < maxRetryCount && !store.state.isClose) {
          setTimeout(() => {
            console.log(`Attempting onclose reconnect (attempt ${retryCount + 1} of ${maxRetryCount})...`);
            connect();
            increaseInterval(); // 重试间隔时间指数递增
          }, retryInterval);
        }
      };
      websocket.onmessage = function(evt) {
        websocketonmessage({
          data: evt.data,
        });
      };
      websocket.onerror = function(evt) {
        store.state.isSend = false;
        if (reconnect && retryCount < maxRetryCount) {
          setTimeout(() => {
            console.log(`now:${new Date().toLocaleTimeString()} Attempting onerror reconnect (attempt ${retryCount + 1} of ${maxRetryCount})...`);
            connect();
            increaseInterval(); // 重试间隔时间指数递增
          }, retryInterval);
        }
      };
    }
    connect();
  });
}


// 默认语言
export function getDefaultLanguage () {
  const defaultLanguage = store.state.language;
  const language = navigator.language
  const clientLanguage = (language != 'zh-CN' && language != 'zh-TW') ? language.substring(0, 2) : language
  const messages = [
    'zh-CN',
    'zh-TW',
    'ar',
    'en',
    'es',
    'fr',
    'id',
    'ja',
    'ko',
    'pt',
    'ru',
    'th',
    'he',
    'tr',
    'hi',
    'vi',
    'de',
    'it',
    'kk',
    'ms'
  ]
  const local = messages.indexOf(clientLanguage) > -1 ? clientLanguage : defaultLanguage
  return local
}

export function resetPrice(price) {
  if (!price || isNaN(price)) {
    price = 0
  }
  let rate = store.state.moneyRate
  let symbol = store.state.moneySymbol
  price = parseFloat(price)
  return `${symbol}${parseFloat(price * rate).toFixed(2)}`
}